import styled from "styled-components";
import JSONPretty from "react-json-pretty";
import { withAppHOC } from "../../hoc";
import { StyledContentContainer } from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { TableBuilder } from "../../components/TableElements";
import { API_CONFIG } from "../../constants";

const StyledBodyContainer = styled.section`
  font-size: 14px;
  font-weight: normal;
  color: #000;
  word-break: break-all;
  padding: 0px;
  line-height: 20px;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  border-radius: 5px;
`;

const StyledHeading = styled.h2`
  font-size: 16px;
  font-weight: normal;
  color: #000;
  background-color: #e5e5e5;
  padding: 15px;
  margin: 0px;
`;

const StyledContent = styled.section`
  padding: 20px;
  @media (max-width: 600px) {
    word-break: break-all;
    overflow-x: scroll;
  }
`;

const StyledApiHeading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  color: #433cf2;
  margin: 0px 0px 20px;
  padding: 0px;
`;

const APIDocumentation = () => {
  const { API_BASE_URL } = API_CONFIG;

  return (
    <>
      <PageHeader title="API Documentation" />
      <StyledContentContainer>
        <StyledApiHeading>Send Message API</StyledApiHeading>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET
            </p>
            <p>
              <b>URL :</b>{" "}
              <code>{`${API_BASE_URL}/public/send-message?apiid=[YOUR_API_ID]&apitoken=[YOUR_API_TOKEN]&receiver=[RECEIVER]&message=[MESSAGE]&refnumber=[YOUR_REFERENCE_NUMBER]`}</code>
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: "Field",
                  dataSelector: "field",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Description",
                  dataSelector: "description",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Mandatory/Optional",
                  dataSelector: "fieldType",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Remark",
                  dataSelector: "remark",
                  dataType: "string",
                  canSort: false,
                },
              ]}
              tableData={[
                {
                  field: "apiid",
                  description: "Api id provided by us",
                  fieldType: "Mandatory",
                  remark: "",
                },
                {
                  field: "apitoken",
                  description: "Api token provided by us",
                  fieldType: "Mandatory",
                  remark: "",
                },
                {
                  field: "receiver",
                  description:
                    "Receiver mobile number with country code or groupid from groups api",
                  fieldType: "Mandatory",
                  remark: "",
                },
                {
                  field: "message",
                  description: "Message you want to send to receiver",
                  fieldType: "Mandatory",
                  remark: "",
                },
                {
                  field: "refnumber",
                  description:
                    "Your reference number to receive callback from us",
                  fieldType: "Optional",
                  remark: "",
                },
              ]}
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id="json-pretty"
              data={JSON.stringify({
                status: "Success/Failed/Pending",
                message: "Message success or failure message",
              })}
            ></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>
        <StyledApiHeading>Groups List API</StyledApiHeading>
        <StyledBodyContainer>
          <StyledHeading>API Request</StyledHeading>
          <StyledContent>
            <p>
              <b>Method :</b> GET
            </p>
            <p>
              <b>URL :</b>{" "}
              <code>{`${API_BASE_URL}/public/groups?apiid=[YOUR_API_ID]&apitoken=[YOUR_API_TOKEN]`}</code>
            </p>
            <TableBuilder
              tableHeadings={[
                {
                  title: "Field",
                  dataSelector: "field",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Description",
                  dataSelector: "description",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Mandatory/Optional",
                  dataSelector: "fieldType",
                  dataType: "string",
                  canSort: false,
                },
                {
                  title: "Remark",
                  dataSelector: "remark",
                  dataType: "string",
                  canSort: false,
                },
              ]}
              tableData={[
                {
                  field: "apiid",
                  description: "Api id provided by us",
                  fieldType: "Mandatory",
                  remark: "",
                },
                {
                  field: "apitoken",
                  description: "Api token provided by us",
                  fieldType: "Mandatory",
                  remark: "",
                },
              ]}
            />
          </StyledContent>
        </StyledBodyContainer>
        <StyledBodyContainer>
          <StyledHeading>Response</StyledHeading>
          <StyledContent>
            <p>
              <b>Response Type :</b> JSON
            </p>
            <JSONPretty
              id="json-pretty"
              data={JSON.stringify({
                status: "Success/Failed/Pending",
                message: "Transaction Information",
                data: "List of groups with id and name",
              })}
            ></JSONPretty>
          </StyledContent>
        </StyledBodyContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(APIDocumentation);
