import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDashboard,
  faMessage,
  faListAlt,
  faGears,
  faBook,
  faCubes,
} from "@fortawesome/free-solid-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";

const StyledNavigation = styled.nav`
  width: 100%;
  height: auto;
  background-color: ${({
    theme: {
      colors: { primaryColor },
    },
  }) => (primaryColor ? primaryColor : `#0095ff`)};
  margin: 0px;
  padding: 0px;
  display: block;
  @media (max-width: 600px) {
    display: none;
    &.show {
      display: block;
    }
  }
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      display: inline-block;
      margin: 0px;
      padding: 0px;
      position: relative;
      transition: background-color 0.5s;
      border-right: 1px solid
        ${({
          theme: {
            colors: { secondaryColor },
          },
        }) => (secondaryColor ? secondaryColor : `#0078ce`)};

      @media (max-width: 600px) {
        display: block;
      }
      &:hover {
        background-color: ${({
          theme: {
            colors: { secondaryColor },
          },
        }) => (secondaryColor ? secondaryColor : `#0078ce`)};
        @media (max-width: 600px) {
          background-color: transparent;
        }
      }
      &:hover > ul {
        display: block;
      }
      a {
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
        margin: 0px;
        padding: 12px 20px;
        text-decoration: none;
        display: block;
        &.active {
          background-color: ${({
            theme: {
              colors: { secondaryColor },
            },
          }) => (secondaryColor ? secondaryColor : `#0078ce`)};
          @media (max-width: 600px) {
            background-color: transparent;
          }
        }
      }
      ul {
        position: absolute;
        left: -1px;
        display: none;
        list-style: none;
        width: auto;
        min-width: 300px;
        height: auto;
        margin: 0px;
        padding: 0px;
        background-color: ${({
          theme: {
            colors: { secondaryColor },
          },
        }) => (secondaryColor ? secondaryColor : `#0078ce`)};
        z-index: 999999;
        @media (max-width: 600px) {
          display: block;
          position: static;
        }
        li {
          display: block;
          margin: 0px;
          padding: 0px;
          border-right: 0px;
          border-bottom: 1px solid
            ${({
              theme: {
                colors: { tertiaryColor },
              },
            }) => (tertiaryColor ? tertiaryColor : `#0067b0`)};
          &:hover {
            background-color: ${({
              theme: {
                colors: { tertiaryColor },
              },
            }) => (tertiaryColor ? tertiaryColor : `#0067b0`)};
          }
          a {
            font-size: 14px;
            font-weight: normal;
            color: #ffffff;
            margin: 0px;
            padding: 12px 15px;
            text-decoration: none;
            display: block;
            transition: background-color 0.5s;
            @media (max-width: 600px) {
              padding-left: 40px;
            }
            &.active {
              background-color: ${({
                theme: {
                  colors: { tertiaryColor },
                },
              }) => (tertiaryColor ? tertiaryColor : `#0067b0`)};
            }
          }
        }
      }
    }
  }
`;

const Navigation = ({ showNav }) => (
  <StyledNavigation className={showNav ? "show" : ""}>
    <ul>
      <li>
        <NavLink to="/" title="Dashboard">
          <FontAwesomeIcon icon={faDashboard} /> Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/plans"
          title="Plans"
          className={({ isActive }) => (isActive ? `active` : ``)}
        >
          <FontAwesomeIcon icon={faCubes} /> Plans
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/messages"
          title="Messages"
          className={({ isActive }) => (isActive ? `active` : ``)}
        >
          <FontAwesomeIcon icon={faMessage} /> Messages
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/groups"
          title="Groups"
          className={({ isActive }) => (isActive ? `active` : ``)}
        >
          <FontAwesomeIcon icon={faUsers} /> Groups
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/orders"
          title="Orders"
          className={({ isActive }) => (isActive ? `active` : ``)}
        >
          <FontAwesomeIcon icon={faListAlt} /> Orders
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/api-credentials"
          title="API Credentials"
          className={({ isActive }) => (isActive ? `active` : ``)}
        >
          <FontAwesomeIcon icon={faKey} /> API Credentials
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/api-settings"
          title="API Settings"
          className={({ isActive }) => (isActive ? `active` : ``)}
        >
          <FontAwesomeIcon icon={faGears} /> API Settings
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/api-documentation"
          title="API Documentation"
          className={({ isActive }) => (isActive ? `active` : ``)}
        >
          <FontAwesomeIcon icon={faBook} /> API Documentation
        </NavLink>
      </li>
    </ul>
  </StyledNavigation>
);

export default Navigation;
