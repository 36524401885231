import { memo } from "react";
import { StyledHeader } from "../styled";
import Logo from "../Logo/Logo";
import AccountSection from "../AccountSection/AccountSection";
import LogoutSection from "../LogoutSection/LogoutSection";
import { StyledHeaderLogoContainer, StyledAccountContainer } from "../styled";

const Header = memo(({ fullName, profilePhoto, onClickLogout }) => {
  return (
    <StyledHeader>
      <StyledHeaderLogoContainer>
        <Logo />
      </StyledHeaderLogoContainer>
      <StyledAccountContainer>
        <AccountSection fullName={fullName} profilePhoto={profilePhoto} />
        <LogoutSection onClickLogout={onClickLogout} />
      </StyledAccountContainer>
    </StyledHeader>
  );
});

export default Header;
