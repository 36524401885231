import { useState } from "react";
import moment from "moment";
import { withAppHOC } from "../../hoc";
import { StyledContentContainer } from "../../components/styled";
import { MessageBox, Button, Fieldset } from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { getAuthData } from "../../utils";
import styled from "styled-components";

const StyledDashboardContainer = styled.section`
  text-align: center;
  h1 {
    font-size: 26px;
    margin: 0px;
    padding: 0px;
  }
  p {
    font-size: 16px;
    margin: 20px 0px 0px 0px;
    padding: 0px;
    &.expired {
      color: red;
    }
  }
  h2 {
    font-size: 20px;
    margin: 20px 0px;
    padding: 0px;
  }
  h3 {
    font-size: 50px;
    margin: 40px 0px;
  }
`;

const Dashboard = (props) => {
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: "",
    responseMessage: "",
    pairingCode: "",
  });
  const {
    userProfile: { fullName, planExpiry, isPlanExpired, connectionStatus },
  } = getAuthData();

  const connect = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      const response = await API.post(`/whatsapp/connect`);
      const { status, message, data } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setState((prevState) => ({
          ...prevState,
          pairingCode: data,
        }));
        setTimeout(() => {
          window.location.href = "/";
        }, 60000 * 5);
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const disconnect = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      const response = await API.delete(`/whatsapp/disconnect`);
      const { status, message } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        const { readProfile } = props;
        readProfile();
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  return (
    <>
      <Loader isLoading={state.isLoading} />
      <StyledContentContainer>
        <StyledDashboardContainer>
          <h1>Welcome {fullName}</h1>
          {planExpiry !== null ? (
            <>
              {isPlanExpired ? (
                <p className={!isPlanExpired ? "expired" : ""}>
                  Your plan is expired on{" "}
                  {moment(planExpiry).format("Do MMMM, YYYY")}
                </p>
              ) : (
                <p>
                  Your current plan will expire on{" "}
                  {moment(planExpiry).format("Do MMMM, YYYY")}
                </p>
              )}
            </>
          ) : (
            <p>
              You dont have an active plan, Please buy a plan to get started
            </p>
          )}
          {connectionStatus === "Connected" ? (
            <>
              <h2 className="green">
                Your whatsapp account is{" "}
                <span style={{ color: "green" }}>{connectionStatus}</span>
              </h2>
              {state.responseStatus && state.responseMessage && (
                <Fieldset>
                  <MessageBox
                    status={state.responseStatus}
                    message={state.responseMessage}
                  />
                </Fieldset>
              )}
              <Button
                onClick={disconnect}
                style={{
                  maxWidth: "300px",
                }}
                className="red"
              >
                Disconnect
              </Button>
            </>
          ) : state.pairingCode !== "" ? (
            <>
              <h2>
                Please use the below pairing code to connect and wait for the
                sync to finish.
              </h2>
              <h3>{state.pairingCode}</h3>
            </>
          ) : (
            <>
              <h2 className="red">
                Your whatsapp account is{" "}
                <span style={{ color: "red" }}>{connectionStatus}</span>
              </h2>
              {state.responseStatus && state.responseMessage && (
                <Fieldset>
                  <MessageBox
                    status={state.responseStatus}
                    message={state.responseMessage}
                  />
                </Fieldset>
              )}
              <Button
                onClick={connect}
                style={{ maxWidth: "300px" }}
                className="green"
              >
                Connect
              </Button>
            </>
          )}
        </StyledDashboardContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(Dashboard);
