import { memo } from "react";
import styled from "styled-components";
import moment from "moment";

const StyledCopyrightsText = styled.p`
  font-size: 13px;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
  color: #979797;
`;

const Copyrights = memo(() => (
  <StyledCopyrightsText>
    Copyrights &copy; {moment().year()} WhatsApp API
  </StyledCopyrightsText>
));

export default Copyrights;
