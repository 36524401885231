import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    font-family: sans-serif;;
    margin: 0px;
    padding: 0px;
    background-color: #ffffff;
  }

  /*** SCROLLBAR ***/
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #9f9f9f;
    border-radius: 10px;
    border: 4px solid #ffffff;
  }
`;

export default GlobalStyles;
