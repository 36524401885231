import { memo } from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: ${({ theme: { colors } }) =>
    colors.primaryColor || `#3049f8`};
  border-radius: 5px;
  padding: 14px 20px;
  outline: 0px;
  cursor: pointer;
  border-width: 0px 5px 5px 0px;
  border-right-color: ${({ theme: { colors } }) =>
    colors.secondaryColor || `#3049f8`};
  border-bottom-color: ${({ theme: { colors } }) =>
    colors.secondaryColor || `#3049f8`};
  width: 100%;
  &:hover {
    background-color: ${({ theme: { colors } }) =>
      colors.secondaryColor || `#3049f8`};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #696969;
    border-right-color: #000000;
    border-bottom-color: #000000;
    &:hover {
      background-color: #696969;
    }
  }
  &.red {
    background-color: #ff0000;
    border-right-color: #af0202;
    border-bottom-color: #af0202;
    &:hover {
      background-color: #af0202;
    }
  }
  &.green {
    background-color: #04930a;
    border-right-color: #00b107;
    border-bottom-color: #00b107;
    &:hover {
      background-color: #00b107;
    }
  }
`;

const Button = memo(({ children, disabled, ...rest }) => (
  <StyledButton {...rest} disabled={disabled}>
    {children}
  </StyledButton>
));

export default Button;
