import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { withAppHOC } from "../../hoc";
import { StyledContentContainer } from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button, MessageBox } from "../../components/FormElements";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import styled from "styled-components";
import { formatCurrency } from "../../utils";

const StyledPlansContainer = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 20px;
`;

const StyledPlanCard = styled.section`
  width: calc(100% - 42px);
  height: auto;
  padding: 20px;
  margin: 0;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  display: flex;
  cursor: pointer;
  &.active {
    border: 5px solid
      ${({ theme: { colors } }) => colors.primaryColor || `#3049f8`};
  }
`;

const StyledPlanDetailsContainer = styled.section`
  flex: 1;
  h1 {
    font-size: 30px;
    margin: 0px;
    padding: 0px;
    color: ${({ theme: { colors } }) => colors.primaryColor || `#3049f8`};
    margin-bottom: 15px;
  }
  p {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    color: #646464;
  }
`;

const StyledPlanAmountContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  h1 {
    font-size: 24px;
    margin: 0px;
    padding: 0px;
    color: green;
  }
  del {
    font-size: 16px;
  }
`;

const Plans = () => {
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: "",
    responseMessage: "",
    plansId: "",
  });

  const readPlans = async () => {
    try {
      const response = await API.get(`/plans`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const createOrder = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      const response = await API.post(`/orders`, {
        plansId: state.plansId,
      });
      const { status, message, data } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        if (data?.paymentUrl && data?.paymentUrl !== "") {
          window.location.href = data?.paymentUrl;
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["plans"],
    queryFn: readPlans,
    cacheTime: 0,
    staleTime: Infinity,
  });

  const renderPageHeading = () => (
    <PageHeader title="Plans" showAddButton={false} showReloadData={false} />
  );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        {data?.length > 0 && (
          <StyledPlansContainer>
            {data?.map((item, index) => {
              const { id, planName, price, discountPercentage } = item;

              return (
                <StyledPlanCard
                  key={`plancard-${index}`}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      plansId: id,
                    }))
                  }
                  className={state.plansId === id ? "active" : ""}
                >
                  <StyledPlanDetailsContainer>
                    <h1>{planName}</h1>
                    <p>
                      You will be able send Unlimited Messages to individual
                      mobile number or groups
                    </p>
                  </StyledPlanDetailsContainer>
                  <StyledPlanAmountContainer>
                    {discountPercentage && discountPercentage !== 0 ? (
                      <>
                        <h1>
                          {formatCurrency(
                            price - (price / 100) * discountPercentage
                          )}
                        </h1>
                        <del>{formatCurrency(price)}</del>
                      </>
                    ) : (
                      <h1>{formatCurrency(price)}</h1>
                    )}
                  </StyledPlanAmountContainer>
                </StyledPlanCard>
              );
            })}
          </StyledPlansContainer>
        )}
        <Button
          onClick={createOrder}
          disabled={state.plansId === "" || state.isLoading}
        >
          Buy Now
        </Button>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(Plans);
