import { useState } from "react";
import { widthAuthHOC } from "../../hoc";
import { Heading1 } from "../../components/Headings";
import {
  Form,
  Fieldset,
  MobileInput,
  PasswordInput,
  OTPInput,
  Button,
  MessageBox,
} from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import {
  StyledAuthLinks,
  StyledLink,
  StyledButtonAsLink,
  StyledAuthFooterLinkText,
  StyledPara,
  StyledTextHighlight,
} from "../../components/styled";
import API from "../../api";
import { OTP_VERIFICATION_TYPES, RESPONSE_STATUSES } from "../../constants";

const ForgotPassword = () => {
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: "",
    responseMessage: "",
    routeStage: "Forgot Password",
    mobileNumber: "",
    requestToken: "",
    oneTimePassword: "",
    password: "",
    confirmPassword: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));

    try {
      if (state.routeStage === "Forgot Password") {
        const response = await API.post("/authentication/forgot-password", {
          mobileNumber: state.mobileNumber,
        });
        const { status, message, token } = response.data;
        if (status === RESPONSE_STATUSES.SUCCESS) {
          setState((prevState) => ({
            ...prevState,
            responseStatus: "",
            responseMessage: "",
            requestToken: token,
            routeStage: "OTP",
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            responseStatus: RESPONSE_STATUSES.FAILED,
            responseMessage: message,
          }));
        }
      } else if (state.routeStage === "OTP") {
        const response = await API.post("/authentication/verify-otp", {
          requestType: OTP_VERIFICATION_TYPES.RESET_PASSWORD,
          requestToken: state.requestToken,
          mobileNumber: state.mobileNumber,
          oneTimePassword: state.oneTimePassword,
        });
        const { status, message, token } = response.data;
        if (status === RESPONSE_STATUSES.SUCCESS) {
          setState((prevState) => ({
            ...prevState,
            responseStatus: "",
            responseMessage: "",
            requestToken: token,
            routeStage: "Change Password",
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            responseStatus: RESPONSE_STATUSES.FAILED,
            responseMessage: message,
            requestToken: token,
          }));
        }
      } else if (state.routeStage === "Change Password") {
        const response = await API.post("/authentication/reset-password", {
          requestToken: state.requestToken,
          password: state.password,
          confirmPassword: state.confirmPassword,
        });
        const { status, message } = response.data;
        if (status === RESPONSE_STATUSES.SUCCESS) {
          setState((prevState) => ({
            ...prevState,
            responseStatus: RESPONSE_STATUSES.SUCCESS,
            responseMessage: "Your password has been successfully changed",
            routeStage: "Forgot Password",
            mobileNumber: "",
            requestToken: "",
            oneTimePassword: "",
            password: "",
            confirmPassword: "",
          }));
          setTimeout(() => (window.location.href = "/login"), 5000);
        } else {
          setState((prevState) => ({
            ...prevState,
            responseStatus: RESPONSE_STATUSES.FAILED,
            responseMessage: message,
          }));
        }
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const resendOTP = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));

    try {
      const response = await API.post("/authentication/resend-otp", {
        requestToken: state.requestToken,
        mobileNumber: state.mobileNumber,
      });
      const { status, message, token } = response.data;
      setState((prevState) => ({
        ...prevState,
        responseStatus: status,
        responseMessage: message,
        requestToken: token,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  return (
    <>
      <Loader isLoading={state.isLoading} />
      <Heading1 textAlign="center">
        {state.routeStage === "Forgot Password"
          ? "Forgot Password ?"
          : state.routeStage === "OTP"
          ? "Verify & Proceed"
          : "Change Password"}
      </Heading1>
      <Form action="#" method="POST" onSubmit={onSubmit}>
        {state.routeStage === "Forgot Password" && (
          <Fieldset>
            <MobileInput
              value={state.mobileNumber}
              onChange={(value) =>
                setState((prevState) => ({ ...prevState, mobileNumber: value }))
              }
              placeholder="Mobile Number"
              disabled={state.isLoading}
            />
          </Fieldset>
        )}
        {state.routeStage === "OTP" && (
          <>
            <Fieldset>
              <StyledPara>
                Please enter your OTP sent to{" "}
                <StyledTextHighlight>{state.mobileNumber}</StyledTextHighlight>
              </StyledPara>
            </Fieldset>
            <Fieldset>
              <OTPInput
                value={state.oneTimePassword}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    oneTimePassword: value,
                  }))
                }
                placeholder="OTP"
                disabled={state.isLoading}
              />
            </Fieldset>
          </>
        )}
        {state.routeStage === "Change Password" && (
          <>
            <Fieldset>
              <PasswordInput
                value={state.password}
                onChange={(value) =>
                  setState((prevState) => ({ ...prevState, password: value }))
                }
                placeholder="Password"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <PasswordInput
                value={state.confirmPassword}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    confirmPassword: value,
                  }))
                }
                placeholder="Confirm Password"
                disabled={state.isLoading}
              />
            </Fieldset>
          </>
        )}
        {state.routeStage === "OTP" && (
          <Fieldset>
            <StyledAuthLinks>
              <StyledButtonAsLink onClick={resendOTP} type="button">
                Resend OTP
              </StyledButtonAsLink>
              <StyledLink to="/" title="WhatsApp API"></StyledLink>
            </StyledAuthLinks>
          </Fieldset>
        )}
        {state.responseStatus && state.responseMessage && (
          <Fieldset>
            <MessageBox
              status={state.responseStatus}
              message={state.responseMessage}
            />
          </Fieldset>
        )}
        <Fieldset>
          <Button disabled={state.isLoading}>
            {state.routeStage === "Forgot Password"
              ? "Send OTP"
              : state.routeStage === "OTP"
              ? "Verify & Proceed"
              : "Submit"}
          </Button>
        </Fieldset>
      </Form>
      {state.routeStage === "Forgot Password" && (
        <StyledAuthFooterLinkText>
          <StyledLink to="/login" title="Login">
            &larr; Back to Login
          </StyledLink>
        </StyledAuthFooterLinkText>
      )}
    </>
  );
};

export default widthAuthHOC(ForgotPassword);
