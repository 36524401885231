const { protocol, host } = window.location;

const API_CONFIG = {
  API_BASE_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8080/v1"
      : `${protocol}//apis.${host.replace("www.", "")}/v1`,
  HEADERS: {
    POST: {
      headers: {
        "Content-Type": "application/json",
      },
    },
    GET: {
      headers: {
        "Content-Type": "application/json",
      },
    },
    PUT: {
      headers: {
        "Content-Type": "application/json",
      },
    },
    DELETE: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  },
};

const ROUTE_TYPES = {
  PUBLIC: "PUBLIC",
  PROTECTED: "PROTECTED",
  REDIRECT_IF_LOGIN: "REDIRECT_IF_LOGIN",
};

const RESPONSE_STATUSES = {
  PENDING: "Pending",
  SUCCESS: "Success",
  FAILED: "Failed",
  EXPIRED: "Expired",
  TWOFA: "TWOFA",
};

const LOGIN_TYPES = {
  PASSWORD: "PASSWORD",
  OTP: "OTP",
};

const OTP_VERIFICATION_TYPES = {
  REGISTER: "REGISTER",
  LOGIN: "LOGIN",
  RESET_PASSWORD: "RESET_PASSWORD",
  GENERATE_API_CREDENTIALS: "GENERATE_API_CREDENTIALS",
  UPDATE_API_SETTINGS: "UPDATE_API_SETTINGS",
  TWOFA: "TWOFA",
};

const MOBILE_VERIFICATION_STATUSES = {
  PENDING: "Pending",
  VERIFIED: "Verified",
};

const STATUSES = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

const REQUEST_METHODS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
};

const RESPONSE_TYPES = {
  JSON: "JSON",
};

export {
  API_CONFIG,
  ROUTE_TYPES,
  RESPONSE_STATUSES,
  LOGIN_TYPES,
  OTP_VERIFICATION_TYPES,
  MOBILE_VERIFICATION_STATUSES,
  STATUSES,
  REQUEST_METHODS,
  RESPONSE_TYPES,
};
