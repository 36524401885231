import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
  StyledStatusPill,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { MessageBox, SearchInput } from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getSearchParams } from "../../utils";

const Orders = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const readData = async () => {
    try {
      const response = await API.get(
        `/orders?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["orders", paginationData],
    queryFn: readData,
    cacheTime: 0,
    staleTime: Infinity,
  });

  const reloadData = async () => await refetch();

  const filterData = async (e) => {
    e.preventDefault();
    await refetch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setSearchQuery("");
    refetch();
  };

  const renderPageHeading = () => (
    <PageHeader
      title="Orders"
      showAddButton={false}
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      <Loader isLoading={isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <SearchInput
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder="Search Order Id"
                disabled={isLoading}
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "Order Id",
                  dataSelector: "orderId",
                  dataType: "string",
                },
                {
                  title: "Plan Name",
                  dataSelector: "planName",
                  dataType: "string",
                },
                {
                  title: "Subtotal",
                  dataSelector: "price",
                  dataType: "string",
                  cellrenderer: (value) => formatCurrency(value) || "N/A",
                },
                {
                  title: "Discount",
                  dataSelector: "discount",
                  dataType: "string",
                  cellrenderer: (value) => formatCurrency(value) || "N/A",
                },
                {
                  title: "CGST",
                  dataSelector: "cGst",
                  dataType: "string",
                  cellrenderer: (value) => formatCurrency(value) || "N/A",
                },
                {
                  title: "SGST",
                  dataSelector: "sGst",
                  dataType: "string",
                  cellrenderer: (value) => formatCurrency(value) || "N/A",
                },
                {
                  title: "Total",
                  dataSelector: "total",
                  dataType: "string",
                  cellrenderer: (value) => formatCurrency(value) || "N/A",
                },
                {
                  title: "Payment Id",
                  dataSelector: "paymentsId",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "Payment Status",
                  dataSelector: "paymentStatus",
                  dataType: "string",
                  cellrenderer: (value) => (
                    <StyledStatusPill
                      className={
                        value === RESPONSE_STATUSES.SUCCESS
                          ? "green"
                          : value === RESPONSE_STATUSES.FAILED
                          ? "red"
                          : "yellow"
                      }
                    >
                      {value}
                    </StyledStatusPill>
                  ),
                },
                {
                  title: "Order Status",
                  dataSelector: "orderStatus",
                  dataType: "string",
                  cellrenderer: (value) => (
                    <StyledStatusPill
                      className={
                        value === RESPONSE_STATUSES.SUCCESS
                          ? "green"
                          : value === RESPONSE_STATUSES.FAILED
                          ? "red"
                          : "yellow"
                      }
                    >
                      {value}
                    </StyledStatusPill>
                  ),
                },
                {
                  title: "Date & Time",
                  dataSelector: "createdOn",
                  dataType: "string",
                  cellrenderer: (value) =>
                    moment(value).format("Do MMM YYYY, hh:mm:ss A"),
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(Orders);
